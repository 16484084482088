import React from "react";
import UploadPhotoIcon from "../UploadPhotoModal/UploadPhotoIcon";
import {
  allFileTypes,
  handleImagesSelected,
} from "../../../uiwrappers/ImageUpload/utils";
import Dropzone from "react-dropzone";

interface GalleryUploadProps {
  processImages: any;
  setFiles: any;
  setIsProcessingImages: (arg0: boolean) => void;
  children?: any;
}

export default function GalleryUpload({
  processImages,
  setFiles,
  setIsProcessingImages,
  children,
}: GalleryUploadProps) {
  return (
    <Dropzone
      accept={allFileTypes}
      multiple={true}
      onDrop={async (acceptedFiles) => {
        const formattedFiles = await handleImagesSelected(
          acceptedFiles,
          processImages
        );
        setFiles(formattedFiles);
        setIsProcessingImages(false);
      }}
    >
      {({ getRootProps, getInputProps, open }) => (
        <div
          {...getRootProps()}
          onClick={open}
          className={
            !children
              ? "flex h-full bg-white items-center justify-center border-dashed cursor-pointer border-2 border-gray-200"
              : ""
          }
        >
          <div className="flex flex-col items-center space-y-1 text-center">
            {children}
            {!children && (
              <div className="flex flex-col items-center justify-center space-y-1 text-center w-full h-full">
                <UploadPhotoIcon />
                <div className="flex text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative pointer-events-none cursor-pointer sm:bg-white rounded-md text-body-normal focus-within:outline-none focus:outline-none"
                  >
                    <span className="bg-white inline text-sm-medium text-blue-600">
                      Upload{" "}
                    </span>
                    <span className="bg-white inline text-sm-normal">
                      a photo or video
                    </span>
                  </label>
                </div>
              </div>
            )}
            <input {...getInputProps()} />
          </div>
        </div>
      )}
    </Dropzone>
  );
}
